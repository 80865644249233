import { useEffect, useState } from "react";
import { useRouter } from "next/router";
import axios from "axios";
import Head from "next/head";
import styles from "../styles/spinner.module.css";

export default function create() {
    const [question, setQuestion] = useState("");
    const [loading, setLoading] = useState(false);
    const router = useRouter();

    useEffect(() => {
        document.getElementById("input").focus();
        document.getElementById("input").click();
    }, []);

    const updateQuestion = () => {
        setQuestion(document.getElementById("input").value);
    };

    const createPoll = async () => {
        const instance = axios.create({
            baseURL: process.env.baseurlbackend,
        });
        setLoading(true);

        try {
            const res = await instance.post("/create", { question });
            router.push(
                process.env.baseurl +
                    "share?id=" +
                    res.data.id +
                    "&bid=" +
                    res.data._id
            );
        } catch (err) {
            console.log(err);
            alert("error while performing post request #01");
        } finally {
            setLoading(false);
        }
    };

    return (
        <div>
            <Head>
                <title>Create a simple yes/no poll</title>
                <meta
                    name="description"
                    content="use this tool to create a poll"
                />
            </Head>
            <div className="w-screen h-screen grid grid-rows-2 grid-cols-1 md:grid-rows-1 md:grid-cols-2">
                <div className="flex flex-col items-center md:justify-center mt-8 md:mt-0 md:bg-gray-100">
                    <h1 className="text-7xl font-extrabold text-center">
                        JGS Poll
                    </h1>
                    <p>Decide as a group.</p>
                    <p>
                        Made with ❤️ by{" "}
                        <a
                            href="https://github.com/JGStyle"
                            className="text-blue-500"
                        >
                            JGS
                        </a>
                    </p>
                </div>

                <div className="flex flex-col items-center text-center md:justify-center">
                    <h2 className="text-3xl font-bold inline md:mt-28">
                        "{question}"
                    </h2>
                    <p>
                        <input
                            type="text"
                            id="input"
                            className="bg-gray-100 rounded-lg px-3 mt-4"
                            placeholder="Enter question"
                            onChange={updateQuestion}
                        ></input>
                    </p>

                    <div className="w-10/12 grid grid-cols-1 gap-x-2 h-12 mt-12 lg:gap-x-4 lg:w-8/12 xl:w-1/2">
                        <button
                            className="bg-gradient-to-r from-blue-400 to-purple-500 rounded-lg text-white hover:shadow-xl transition transition-duration-200"
                            onClick={createPoll}
                        >
                            {!loading ? (
                                "Create Poll"
                            ) : (
                                <div className={styles.spinner}></div>
                            )}
                        </button>
                    </div>
                </div>
            </div>
        </div>
    );
}
